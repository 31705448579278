@import '~@angular/cdk/overlay-prebuilt.css'

@import 'tailwindcss/base'
@import 'tailwindcss/components'
@import 'tailwindcss/utilities'

body, header
  position: relative
  height: 100%

body
  @apply bg-background text-dark
  font-family: 'Inter', sans-serif
  min-height: 100vh
  min-width: 320px

a
  @apply text-violet
  transition: color 0.3s ease-in
  &:hover
    @apply text-violet
  &:focus-visible
    @apply outline-none outline-0  border-b border-b-[violet]

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

*
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  -moz-tap-highlight-color: rgba(0, 0, 0, 0)

@keyframes flash
  0%, 50%, 100%
    opacity: 0.2
  25%, 75%
    opacity: 0.1

.dialog-backdrop-blur
  background: #7070703D
  backdrop-filter: blur(25px)

.dialog-overlay-panel
  @apply flex flex-grow items-center justify-center
  height: 100vh

.gray-button
  @apply block bg-[#1E1E1E] p-4 w-full cursor-pointer
  &[disabled]
    @apply opacity-70 cursor-default

.dark-button
  @apply bg-dark text-white p-[16px] text-[15px] leading-none cursor-pointer rounded-[8px] transition
  box-shadow: 0 14.3px 25px rgba(0, 0, 0, 0.13), 0 4.25px 7.45px rgba(0, 0, 0, 0.22)
  &:hover
    @apply bg-[#282230]
  &[disabled]
    @apply bg-[#E3E3E3] text-[#B1B1B1] cursor-default shadow-none
    &:hover
      @apply bg-[#E3E3E3]
  &.small
    @apply py-[8px] px-[12px]

.icon-button
  @apply flex justify-center items-center w-[32px] h-[32px] cursor-pointer
  svg-icon
    path
      @apply transition-all
  &:hover
    svg-icon
      path
        @apply fill-violet

.violet-button
  @apply flex flex-row items-center justify-center text-dark
  @apply bg-[#ffffff80] p-[16px] text-[15px] leading-none cursor-pointer rounded-[8px] transition
  box-shadow: 0 14.3px 25px rgba(0, 0, 0, 0.03), 0 4.25px 7.45px rgba(0, 0, 0, 0.1)
  &:hover
    @apply bg-background text-dark
  &[disabled]
    @apply bg-violet-third opacity-70 cursor-default
    &:hover
      @apply bg-violet-third
  .icon
    @apply inline-block mr-[3px] -my-[5px]

.red-button
  @apply violet-button text-red w-full flex flex-row items-center justify-center

.white-button
  @apply bg-white p-[16px] text-[15px] text-dark leading-none cursor-pointer rounded-[8px] transition border border-border-gray
  box-shadow: 0 14.3px 25px rgba(0, 0, 0, 0.03), 0 4.25px 7.45px rgba(0, 0, 0, 0.1)
  &:hover
    @apply bg-background text-gray
  &[disabled]
    @apply bg-white text-gray opacity-70 cursor-default
    &:hover
      @apply bg-white text-gray
  &.small
    @apply py-[8px] px-[12px]
  .icon
    @apply inline-block mr-[3px] -my-[5px]

.dialog-backdrop.cdk-overlay-backdrop.cdk-overlay-backdrop-showing
  @apply bg-[#FFFFFF80] backdrop-blur-[8px]

.cdk-global-overlay-wrapper
  overflow-y: auto

.dialog-background
  @apply block bg-white shadow-lg rounded-[12px]

.dialog-header
  @apply flex flex-row items-center justify-between p-[24px] pb-0

.dialog-title
  @apply text-dark text-[24px] leading-[32px] m-0

.dialog-close
  @apply flex justify-center items-center w-[32px] h-[32px] cursor-pointer
  path
    @apply transition-all
  &:hover
    path
      @apply fill-gray
  &[disabled]
    @apply opacity-50 cursor-default

.dialog-content
  @apply p-[24px] pt-[16px]

.form-label
  @apply block text-[15px] mb-[8px]

.form-input
  @apply block w-full p-[12px] text-[15px] leading-[22px] text-dark border border-border-gray rounded-[8px] transition-all
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.09)
  &.ng-invalid.ng-touched
    @apply border-border-red
  &:focus
    @apply border-violet outline-0
    box-shadow: 0 2px 2px rgba(101, 22, 225, 0.2)
  &::placeholder
    @apply text-[#B1B1B1]

.form-textarea
  @apply form-input min-h-[120px] max-h-[320px]

.form-row
  @apply flex flex-row gap-[16px] w-full mb-[16px] last:mb-0
  .form-field
    @apply mb-0

.form-field
  @apply w-full mb-[16px] last:mb-0 relative

.md-content
  @apply text-[16px] leading-[24px] text-gray
  h1
    @apply text-[24px] leading-[32px] mb-[8px] mt-[20px] font-bold text-dark first:mt-0
  h2
    @apply text-[22px] leading-[30px] mb-[8px] mt-[20px] font-bold text-dark first:mt-0
  h3
    @apply text-[18px] leading-[28px] mb-[8px] mt-[20px] font-bold text-dark first:mt-0
  h4
    @apply text-[16px] leading-[24px] mb-[8px] mt-[20px] font-bold text-dark first:mt-0
  p
    @apply text-[16px] leading-[22px] mb-[8px]
    &:last-child
      @apply mb-0
  hr
    @apply mb-[18px] mt-[12px]
  ul
    @apply mb-[16px]
    li
      @apply text-[16px] leading-[24px] mb-[12px] list-disc ml-[16px]
      &:last-of-type
        @apply mb-0
  pre
    @apply bg-[#cccccc33] p-[10px] mb-[20px] text-[14px] leading-[18px] rounded-[6px]
    white-space: pre-wrap
  & > code
    @apply bg-[#cccccc33] p-[4px] text-[14px] leading-[18px] rounded-[6px] text-dark

.cdk-dialog-container
  &:focus-visible
    outline: none

.text-unwrap
  @apply whitespace-nowrap text-dark

.router-link
  @apply px-[20px] py-[8px] bg-white border border-border-gray rounded-[8px] text-gray hover:text-gray
  &.active
    @apply bg-violet text-white cursor-default
    box-shadow: 0 11px 12px 0 #6516E11F

.back-link
  @apply flex flex-row items-center text-dark cursor-pointer leading-[32px] text-gray hover:text-dark
  svg-icon
    path
      transition: fill 0.3s ease-in
  &:hover
    svg-icon
      path
        @apply fill-dark

.tooltip
  @apply bg-dark border border-dark text-white rounded-[8px] py-[4px] px-[8px] text-[13px] leading-[18px] cursor-default
  box-shadow: 0 6px 22px rgba(86, 61, 124, 0.23)
  h4
    @apply font-bold mb-[6px]
  p
    @apply mb-[13px]
    &:last-of-type
      @apply mb-0

[tooltip]
  *
    pointer-events: none

.title-1
  @apply text-[24px] leading-[32px] font-bold

.group-buttons
  @apply flex flex-nowrap flex-row gap-[6px] p-[6px] bg-background rounded-[8px]
  .group-button
    @apply p-[6px] rounded-[8px] border border-transparent text-center text-gray text-[15px] leading-[24px] grow cursor-pointer transition-all
    &:hover
      @apply text-dark bg-violet-second
    &.active
      @apply bg-white border-border-gray text-dark cursor-default
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.09)
      &:hover
        @apply text-dark
